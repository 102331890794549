import React from "react"
import { Link } from "gatsby"
import styled, { css } from "styled-components"
import { media } from "../styles/media"
import { AiOutlineSwapRight } from "react-icons/ai"
import { IconContext } from "react-icons"
import { BiChevronLeft } from "react-icons/bi"
import { pagePath } from "../constants/link"

const ArrowWhite = () => (
  <IconContext.Provider value={{ color: "white" }}>
    <AiOutlineSwapRight />
  </IconContext.Provider>
)

const ArrowBack = () => (
  <IconContext.Provider value={{ color: "#663100" }}>
    <BiChevronLeft />
  </IconContext.Provider>
)

const Tesla = styled.div`
    display:flex;
    flex-flow:row wrap;
    align-items:center;
    justify-content:space-between;
    width:80%;
    margin:0 auto;
    a{
      font-size:.9em;
      background:${({ theme }) => theme.colors.primary.brown};
      color:white;
      border:2px solid ${({ theme }) => theme.colors.primary.brown};
      display:flex;
      flex-flow:row nowrap;
      align-items:center;
      justify-content:center;
      border-radius:5px;
      padding:.32em 1.62em;
      svg{
        margin-left:.32em;
      }
      :nth-child(1){
        background:white;
        color:${({ theme }) => theme.colors.primary.brown};
      }
    }
    ${() => media.sp(css`
      width:90%;
      flex-flow:column nowrap;
      justify-content:flex-end;
      align-items:flex-end;
      a{
        margin-bottom:10px;
      }
    `
)}
  `

const CommitmentLinks = () => {
  return (
    <Tesla>
      <Link to={pagePath.ourPolicy.path}>
        <ArrowBack />
        戻る
      </Link>
      <Link to={pagePath.productSearch.path}>
        {pagePath.productSearch.text}
        <ArrowWhite />
      </Link>
      <Link to={pagePath.thisMonthProducts.path}>
        {pagePath.thisMonthProducts.text}
        <ArrowWhite />
      </Link>
    </Tesla>
  )
}

export default CommitmentLinks
