import React from "react"
import { graphql, useStaticQuery } from "gatsby"
import Image from "gatsby-image"
import styled, { css } from "styled-components"

import { media } from "../../styles/media"

import SEO from "../../components/SEO"
import Layout from "../../components/Layout"
import HeaderVisual from "../../components/HeaderVisual"
import CommitmentLinks from "../../components/CommitmentLinks"

export const query = graphql`
  {
    strapiOurPolicyWalnuts {
      mainTitle
      mainSubTitle
      MainVisualTop {
        childImageSharp {
          fluid {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
      mainSubVisual {
        childImageSharp {
          fluid {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
      underVisual {
        childImageSharp {
          fluid {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
      walnutsComp {
        id
        photoDetails
        paragraphs
        paragraphTitle
        paraVisual {
          childImageSharp {
            fluid {
              ...GatsbyImageSharpFluid_withWebp
            }
          }
        }
      }
    }
  }
`

const ContentsWrap = styled.div`
  padding: 50px 5% 100px;
  max-width: 1000px;
  margin: 0 auto;
  h3 {
    font-size: 1.32em;
    line-height: 1.62em;
    color: ${({ theme }) => theme.colors.primary.brown};
  }
  .underLink {
    display: block;
    width: 70%;
    margin-left: 15%;
    margin-bottom: 50px;
    cursor: not-allowed;
    ${() =>
      media.sp(css`
        width: 90%;
        margin: 0 auto 30px;
      `)}
  }
`

const WalTopVisual = styled.div`
  width: 100%;
  margin: 0 auto;
  margin-bottom: 50px;
  p {
    line-height: 1.62em;
    padding-bottom: 30px;
    font-size: 0.9em;
  }
  ${() =>
    media.sp(css`
      width: 100%;
    `)}
`
const WalSections = styled.div`
  width: 100%;
  padding-bottom: 80px;
  .walPhoto {
    width: 100%;
    display: flex;
    flex-flow: row wrap;
    justify-content: space-between;
    align-items: flex-end;
    p {
      padding: 0;
      width: 30%;
      font-size: 10px;
    }
    .gatsby-image-wrapper {
      width: 60%;
    }
  }
  .walpara {
    padding: 20px 0 40px;
    font-size: 0.9em;
  }
  ${() =>
    media.sp(css`
      .walPhoto {
        .gatsby-image-wrapper {
          width: 100%;
        }
        p {
          width: 100%;
          font-size: 0.8em;
        }
      }
    `)}
`

const OurPolicyWalnuts = () => {
  const data = useStaticQuery(query)
  const { strapiOurPolicyWalnuts: cont } = data

  return (
    <Layout>
      <SEO>
        <title>自家焙煎くるみについて | フランソア</title>
        <meta name="description" content="		 		 			 			 			自家焙..." />
      </SEO>
      <HeaderVisual
        backImage={cont.MainVisualTop.childImageSharp.fluid}
        mainTitle={cont.mainTitle}
        mainText={cont.mainSubTitle}
      />

      <ContentsWrap>
        <WalTopVisual>
          <Image fluid={cont.mainSubVisual.childImageSharp.fluid} />
        </WalTopVisual>
        {cont.walnutsComp.map((cat, index) => (
          <WalSections key={index}>
            <h3>{cat.paragraphTitle && cat.paragraphTitle}</h3>
            {cat.paragraphs && (
              <p
                className="walpara"
                dangerouslySetInnerHTML={{ __html: cat.paragraphs }}
              />
            )}
            {cat.paraVisual && (
              <div className="walPhoto">
                <Image fluid={cat.paraVisual.childImageSharp.fluid} />
                <p>{cat.photoDetails && cat.photoDetails}</p>
              </div>
            )}
          </WalSections>
        ))}
        {/* <Link className="underLink" to="/product-search/"> */}
        {/* <div className="underLink"> */}
        {/* <Image fluid={cont.underVisual.childImageSharp.fluid} /> */}
        {/* </Link> */}
        {/* </div> */}
        <CommitmentLinks />
      </ContentsWrap>
    </Layout>
  )
}

export default OurPolicyWalnuts
